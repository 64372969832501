import React, { useRef, useState, useEffect } from "react"
import contactStyles from '../../style/contact.module.scss'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'



const ContactForm = () => {
    const [message, setMessage] = useState()
    const [sent, setSent] = useState(false)
    const [captcha, setCaptcha] = useState()
    const intl = useIntl()
    const form = useRef(null)
    const [data, setData] = useState({
        sender: '',
        text: '',
        captcha: '',
        subject: ''
    })

    useEffect(() => {
        setCaptcha(`${window.env.apiUrl}api/captcha`)
    }, [])

    const submit = e => {
        e.preventDefault()


        const {
            sender,
            text,
            captcha,
            subject,
        } = data

        const toSend = {
            text: `From ${sender}\n${text}`,
            captcha,
            subject
        };

        console.log(window.env.apiUrl, toSend)

        fetch(`${window.env.apiUrl}api/send-email`, {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(toSend),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                switch (response.status) {
                    case 400:
                        setMessage(<FormattedMessage id="Contact.form.captcha.error" />)
                        break;
                    case 200:
                        setSent(true)
                        break;
                    default:
                        setMessage(<FormattedMessage id="Contact.form.unknown.error" />)
                        break;
                }
                console.log(message)
            })
            .catch((err) => console.log(err))
    }

    return (

        <form id="contactForm" className={contactStyles.contactFormContainer} ref={form} onSubmit={submit}>

            <div className={contactStyles.contactForm} style={{ display: !sent ? 'block' : 'none' }}>
                <h3><FormattedMessage id="Services.btn.chat" /></h3>
                <fieldset>
                    <input placeholder={intl.formatMessage({ id: "Contact.form.sender.placeholder" })} name="data[sender]" value={data.sender} onChange={e => setData({ ...data, sender: e.target.value })} type="email" pattern=".*@.*\..*$" required autoFocus />
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
                    </svg>
                    <label htmlFor="sender"><FormattedMessage id="Contact.form.sender.label" /></label>

                </fieldset>

                <fieldset>
                    <input placeholder={intl.formatMessage({ id: "Contact.form.subject.placeholder" })} name="data[subject]" value={data.subject} onChange={e => setData({ ...data, subject: e.target.value })} type="text" required autoFocus></input>
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
                    </svg>
                    <label htmlFor="subject"><FormattedMessage id="Contact.form.subject.label" /></label>
                </fieldset>

                <fieldset>
                    <textarea placeholder={intl.formatMessage({ id: "Contact.form.message.placeholder" })} name="data[text]" value={data.text} onChange={e => setData({ ...data, text: e.target.value })} required></textarea>
                    <label htmlFor="message"><FormattedMessage id="Contact.form.message.label" /></label>
                </fieldset>


                <div className={contactStyles.captcha}>

                    <img src={captcha} alt="" />

                    <fieldset>

                        <input placeholder={intl.formatMessage({ id: "Contact.form.captcha.placeholder" })} name="data[captcha]" value={data.captcha} onChange={e => setData({ ...data, captcha: e.target.value })} type="text" required autoFocus></input>
                        <label htmlFor="captcha"><FormattedMessage id="Contact.form.captcha.label" /></label>

                    </fieldset>

                </div>

                <p className={contactStyles.errorMessage}> {message} </p>


                <input type="submit" value={intl.formatMessage({ id: "Contact.form.submit" })} />
            </div>

            <div className={contactStyles.successMessage} style={{ display: sent ? 'block' : 'none' }}>
                <h3><FormattedMessage id="Contact.form.success" /></h3>
            </div>


        </form>


    )
}

export default ContactForm
